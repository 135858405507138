<template>
  <module
    ref="module"
    id="faq"
    title-icon="fa fa-question"
    :title="$t('faq')"
    :use-default-list="false"
  >
    <div slot="global" class="row">
      <div class="col-md-10 mx-auto">

        <card type="plain" class="card-subcategories">
          <template slot="header">
            <h4 class="card-title text-center">{{ $t('faqtitle') }}</h4>
          </template>

          <tabs pills type="info" icons centered tab-content-classes="tab-space">
            <tab-pane id="account" key="account">
              <span slot="title">
                  {{ $t('account') }}
              </span>
              <collapse :activeIndex="startIdxA" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqsA">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane id="billing" key="billing" v-if="!netellerException">
              <span slot="title">
                  {{ $t('walletbilling') }}
              </span>
              <collapse :activeIndex="startIdx" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqs">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane id="traders" key="traders">
              <span slot="title">
                  {{ $t('traders') }}
              </span>
              <collapse :activeIndex="startIdxT" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqsT">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane id="followers" key="followers">
              <span slot="title">
                  {{ $t('followerstxt') }}
              </span>
              <collapse :activeIndex="startIdxF" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqsF">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane id="vps" key="vps">
              <span slot="title">
                  {{ $t('vps') }}
              </span>
              <collapse :activeIndex="startIdxV" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqsV">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane id="affiliate" key="affiliate">
              <span slot="title">
                  {{ $t('affiliatetxt') }}
              </span>
              <collapse :activeIndex="startIdxAFL" :multipleActive="multi">
                <collapse-item :name="i" :key="i" v-for="(q, i) in faqsAFL">
                  <span slot="title" style="text-transform: uppercase;font-weight: bold;">
                    {{ q.question }}
                  </span>
                  <div>
                    <span v-html="q.answer"></span>
                  </div>
                </collapse-item>
              </collapse>
            </tab-pane>

          </tabs>
        </card>
      </div>
    </div>

  </module>
</template>
<script>
  import {Collapse, CollapseItem, Tabs, TabPane, Card} from 'src/components/UIComponents'
  import Constants from 'src/assets/constants';
  const rules = Constants.methods.getRules();

  export default {
    components: {
      TabPane,
      CollapseItem,
      Collapse,
      Tabs,
      Card
    },
    data () {
      return {
        multi: false,
        netellerException: rules.removeIfUserHas(['Neteller']),
        startIdx: 0,
        startIdxA: 0,
        startIdxT: 0,
        startIdxF: 0,
        startIdxV: 0,
        startIdxTT: 0,
        startIdxAFL: 0,
        faqs: [
          { question: this.$t('faq01q'), answer: this.$t('faq01a') },
          { question: this.$t('faq02q'), answer: this.$t('faq02a') },
          { question: this.$t('faq03q'), answer: this.$t('faq03a') },
          { question: this.$t('faq04q'), answer: this.$t('faq04a') },
          { question: this.$t('faq05q'), answer: this.$t('faq05a') },
          { question: this.$t('faq06q'), answer: this.$t('faq06a') },
          { question: this.$t('faq07q'), answer: this.$t('faq07a') },
          { question: this.$t('faq08q'), answer: this.$t('faq08a') },
          { question: this.$t('faq09q'), answer: this.$t('faq09a') },
          { question: this.$t('faq10q'), answer: this.$t('faq10a') },
        ],
        faqsA: [
          { question: this.$t('faqa01q'), answer: this.$t('faqa01a') },
          { question: this.$t('faqa02q'), answer: this.$t('faqa02a') },
          { question: this.$t('faqa03q'), answer: this.$t('faqa03a') },
          { question: this.$t('faqa04q'), answer: this.$t('faqa04a') },
          { question: this.$t('faqa05q'), answer: this.$t('faqa05a') },
          { question: this.$t('faqa06q'), answer: this.$t('faqa06a') },
          { question: this.$t('faqa07q'), answer: this.$t('faqa07a') },
          { question: this.$t('faqa08q'), answer: this.$t('faqa08a') },
          { question: this.$t('faqa09q'), answer: this.$t('faqa09a') },
          { question: this.$t('faqa11q'), answer: this.$t('faqa11a') },
          { question: this.$t('faqa10q'), answer: this.$t('faqa10a') },
        ],
        faqsT: [
          { question: this.$t('faqt01q'), answer: this.$t('faqt01a') },
          { question: this.$t('faqt02q'), answer: this.$t('faqt02a') },
          { question: this.$t('faqt03q'), answer: this.$t('faqt03a') },
          { question: this.$t('faqt04q'), answer: this.$t('faqt04a') },
          { question: this.$t('faqt05q'), answer: this.$t('faqt05a') },
          { question: this.$t('faqt06q'), answer: this.$t('faqt06a') },
          { question: this.$t('faqt07q'), answer: this.$t('faqt07a') },
          { question: this.$t('faqt08q'), answer: this.$t('faqt08a') },
          { question: this.$t('faqt09q'), answer: this.$t('faqt09a') },
          { question: this.$t('faqt10q'), answer: this.$t('faqt10a') },
          { question: this.$t('faqt15q'), answer: this.$t('faqt15a') },
          { question: this.$t('faqt11q'), answer: this.$t('faqt11a') },
          { question: this.$t('faqt12q'), answer: this.$t('faqt12a') },
          { question: this.$t('faqt13q'), answer: this.$t('faqt13a') },
          { question: this.$t('faqt14q'), answer: this.$t('faqt14a') },
        ],
        faqsF: [
          { question: this.$t('faqf01q'), answer: this.$t('faqf01a') },
          { question: this.$t('faqa04q'), answer: this.$t('faqf02a') },
          { question: this.$t('faqf03q'), answer: this.$t('faqf03a') },
          { question: this.$t('faqf04q'), answer: this.$t('faqf04a') },
          { question: this.$t('faqf05q'), answer: this.$t('faqf05a') },
          { question: this.$t('faqf06q'), answer: this.$t('faqf06a') },
          { question: this.$t('faqf08q'), answer: this.$t('faqf08a') },
          { question: this.$t('faqf09q'), answer: this.$t('faqf09a') },
          { question: this.$t('faqf10q'), answer: this.$t('faqf10a') },
          { question: this.$t('faqf11q'), answer: this.$t('faqf11a') },
        ],
        faqsV: [
          { question: this.$t('faqv01q'), answer: this.$t('faqv01a') },
          { question: this.$t('faqv02q'), answer: this.$t('faqv02a') },
          { question: this.$t('faqv03q'), answer: this.$t('faqv03a') },
          { question: this.$t('faqv04q'), answer: this.$t('faqv04a') },
          { question: this.$t('faqv05q'), answer: this.$t('faqv05a') },
          { question: this.$t('faqv06q'), answer: this.$t('faqv06a') },
          { question: this.$t('faqv07q'), answer: this.$t('faqv07a') },
          { question: this.$t('faqv08q'), answer: this.$t('faqv08a') },
          { question: this.$t('faqv09q'), answer: this.$t('faqv09a') },
          { question: this.$t('faqv10q'), answer: this.$t('faqv10a') },
          { question: this.$t('faqv11q'), answer: this.$t('faqv11a') },
        ],
        faqsAFL: [
          { question: this.$t('faqtaf01q'), answer: this.$t('faqtaf01a') },
          { question: this.$t('faqtaf02q'), answer: this.$t('faqtaf02a') },
          { question: this.$t('faqtaf03q'), answer: this.$t('faqtaf03a') },
          { question: this.$t('faqtaf04q'), answer: this.$t('faqtaf04a') },
          { question: this.$t('faqtaf05q'), answer: this.$t('faqtaf05a') },
        ]
      }
    },
    methods: {
      downloadPDF() {
        let request = new XMLHttpRequest();

        const stateChanged = e => {
          if (request.readyState == 4) {
            window.open('/static/docs/HokoCloud-AnalysisMathematics.pdf', '_blank');
          }
        };

        request.addEventListener('readystatechange', stateChanged);

        request.addEventListener('progress', function(e) {
        	var percent_complete = (e.loaded / e.total)*100;
        });

        request.responseType = 'blob';

        // Downloading Doc
        request.open('get', '/static/docs/HokoCloud-AnalysisMathematics.pdf');
        request.send();
      }
    }
  }

</script>
<style>
.footnote {
  font-size: 8px;
}
</style>
